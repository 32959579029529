import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ASSET_SUITE_LOGIN_URL, COOKIE_DETAILS } from "config/default.config";
import mqtt from "precompiled-mqtt";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "store";
import { validateToken } from "store/keycloak/asset-keycloak.action";
import { IPublishDetails } from "store/seqr-ops/seqr-ops.interface";
import { setPublishDetails, setTheme } from "store/seqr-ops/seqr-ops.slice";
import { cookieUtils } from "utils/cookie.utils";
import { encryptUtils } from "utils/encryption.utils";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { assetModelActions } from "./store/asset-model/asset-model.action";

// Lazy load components
const SeqrOpsLTR = lazy(() => import("./containers/default-container.layout/default-container.layout"));
const DialogProvider = lazy(() => import("components/dialogs/DialogProvider"));

function App() {
  const [ready, setReady] = useState<boolean>(false);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [modelsCount, setModelsCount] = useState<number>(0);
  const models = useAppSelector((state) => state.assetModels.rawModels);
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);
  const [localThemeState, setLocalThemeState] = useState<string>("");
  const URL = "tcp://127.0.0.1:1883";
  const client = mqtt.connect(URL);

  client.on("connect", () => {
    client.subscribe("asset-model/ui-notification", (err, data) => {
      // console.log("here", data);
    });
  });

  client.on("message", (topic, message) => {
    const payload = message.toString();
    try {
      const parsedPayload = JSON.parse(payload);
      const { status, username } = parsedPayload;

      let publishDetails: IPublishDetails = {
        publishStatus: status,
        username: username,
      };
      dispatch(setPublishDetails(publishDetails));
    } catch (error) {
      console.error("Error parsing payload:", error);
    }
  });

  useEffect(() => {
    async function fetchData() {
      const count = await assetModelActions.getAllModelsCount();
      setModelsCount(count);
      setReady(true);
    }
    if (isLogged) {
      fetchData();
    }
    dispatch(assetModelActions.getDomainDetails());
  }, [models, isLogged]);

  useEffect(() => {
    console.log("Changes reflected : Performance upgrade");
    const validateAuth = async () => {
      let cookieName = COOKIE_DETAILS.COOKIE_NAME;
      let encryptedCookieData = cookieUtils.getCookie(cookieName);
      let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
      if (decryptedCookieData?.themeType === "LIGHT") {
        dispatch(setTheme(false));
      } else if (decryptedCookieData?.themeType === "DARK") {
        dispatch(setTheme(true));
      }
      if (
        decryptedCookieData?.sessionId != null &&
        decryptedCookieData?.sessionId !== undefined &&
        decryptedCookieData?.sessionId !== ""
      ) {
        const validation = await validateToken(
          decryptedCookieData?.sessionId,
          setIsLogged
        );
        if (!validation) {
          setIsLogged(false);
          localStorage.clear();
          cookieUtils.deleteCookie(cookieName);
          window.location.replace(ASSET_SUITE_LOGIN_URL);
        }
      } else if (
        decryptedCookieData?.sessionId == null ||
        decryptedCookieData?.sessionId === undefined ||
        decryptedCookieData?.sessionId === ""
      ) {
        setIsLogged(false);
        localStorage.clear();
        cookieUtils.deleteCookie(cookieName);
        window.location.replace(ASSET_SUITE_LOGIN_URL);
      }
    };
    validateAuth();
  }, []);

  useEffect(() => {
    if (theme) {
      setLocalThemeState("dark");
    } else {
      setLocalThemeState("light");
    }
  }, [theme]);

  useEffect(() => {
    document.body.id = theme ? "dark" : "light";
  }, [theme]);

  if (!ready) {
    return null;
  }

  return (
    <div className="App" id={localThemeState}>
      <DialogProvider>
        <Suspense fallback={<div className="loading-spinner"></div>}>
          <Routes>
            <Route
              path="/"
              element={<SeqrOpsLTR modelsCount={modelsCount} />}
            ></Route>
          </Routes>
        </Suspense>
      </DialogProvider>

      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? (
        <div className="loader-row">
          <div className="loader">
            <div id="ld4">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
